import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import IndexStyles from "../styles/indexStyles"

const IndexPage = ({ data }) => {
  const posts = data.allContentfulLatest.edges
  // const last = data.allContentfulLatest.edges.slice(-1).pop().node

  return (
    <Layout>
      <SEO title="home" keywords={[`latest stories`]} />
      <IndexStyles>
        {/* <div className="bar">
          <span className="bar-text">Latest News</span>
          <hr />
        </div> */}
        <div className="story">
          {posts.map(post => (
            <div className="story-card" key={post.node.id}>
              <Link to={`latest/${post.node.slug}`}>
                <Img
                  className="story-card-image"
                  fluid={post.node.hero.fluid}
                  alt={post.node.title}
                />
                <h4 className="story-card-title">{post.node.title}</h4>
              </Link>
              
            </div>
          ))}
        </div>
      </IndexStyles>
    </Layout>
  )
}
export const LatestPage = graphql`
  query Latest {
    allContentfulLatest {
      edges {
        node {
          slug
          id
          title
          hero {
            fluid(maxWidth: 500, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
