import styled from "styled-components"

const IndexStyles = styled("div")`
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
    color: white;
  }
  .story-card {
    height: auto;
    width: 700px;
    margin: 40px;
    text-align: left;
    @media (max-width: 700px) {
      width: 90vw;
    }
    filter: drop-shadow(0 0 30px #333);
    .story-card-title {
      padding: 5px;
      font-size: 20px;
      transform: translateY(-60px);
      background-color: rgba(0, 0, 0, .6);
    }
    .story-card-image {
      img {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
      }
    }
  }
`
export default IndexStyles

/* width: 80vw;
margin: 0 auto;
box-sizing: border-box;
padding-top: 30px;
@media(max-width: 500px) {
  width: 95vw;
}
.bar {
  margin: 0 auto;
  .bar-text {
    background-color: black;
    color: white;
    padding: 5px 8px 5px 8px;
  }
  hr {
    background-color: black;
    height: 10px;
  }
}
.story {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
  text-align: center;
  hr {
    @media(min-width: 1250px) {
      display: none;
    }
  }
  .story-card {
    padding: 5px;
  }
  .story-card-image {
    width: 500px;
    @media(max-width: 700px) {
      width: 400px;
    }
    @media(max-width: 500px) {
      width: 95vw;
    }
  }
  .story-card-title {
    margin-top: 10px;
    font-size: 16px;
    
  }
}
a {
  text-decoration: none;
  color: black;
} */
